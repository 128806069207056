import Vue from "vue";
import VueI18n from "vue-i18n";
import { FEATURE_FLAGS } from "@/enums/featureFlags";
import LOCALE_DE from "@/locales/de.json";
import LOCALE_EN from "@/locales/en.json";

Vue.use(VueI18n);

const defaultLocale = "de";

function loadLocaleMessages() {
  return { de: LOCALE_DE, en: LOCALE_EN };
}

function getFallbackLocale() {
  return process.env.VUE_APP_I18N_LOCALE || defaultLocale;
}

function getDefaultLocale() {
  let languages = Object.getOwnPropertyNames(loadLocaleMessages());

  const cachedLocale = localStorage.getItem("locale");
  if (cachedLocale && languages.includes(cachedLocale)) return cachedLocale;

  const languagePartial = navigator.language.split("-")[0];
  if (languages.includes(languagePartial)) return languagePartial;

  return getFallbackLocale();
}

export default new VueI18n({
  locale: FEATURE_FLAGS.internationalization
    ? getDefaultLocale()
    : defaultLocale,
  fallbackLocale: getFallbackLocale(),
  messages: loadLocaleMessages()
});
