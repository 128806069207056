import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: () =>
      import(/* webpackChunkName: "main" */ "@/pages/LandingPage")
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "privacypolicypage" */ "@/pages/PrivacyPolicyPage"
      )
  },
  {
    path: "/team",
    name: "Team",
    component: () => import(/* webpackChunkName: "team" */ "@/pages/TeamPage")
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: () =>
      import(/* webpackChunkName: "imprint" */ "@/pages/ImprintPage")
  },
  {
    path: "/editor",
    name: "Editor",
    component: () =>
      import(/* webpackChunkName: "editor" */ "@/pages/EditorPage")
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "@/pages/ContactPage")
  },
  {
    path: "/patterns",
    name: "Patterns",
    component: () =>
      import(/* webpackChunkName: "patterns" */ "@/pages/PatternsPage")
  },
  {
    path: "/patterns/:id",
    name: "Pattern",
    component: () =>
      import(/* webpackChunkName: "pattern" */ "@/pages/PatternDetailPage")
  },
  {
    path: "/go",
    name: "Go",
    component: () =>
      import(/* webpackChunkName: "redirect" */ "@/pages/RedirectAffiliate")
  },
  {
    path: "/:catchAll(.*)*",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "notfound" */ "@/components/NotFoundPage")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  }
});

export default router;
